import React, { useState, useRef, useEffect, useContext } from "react";
import { FaMapMarkedAlt } from "react-icons/fa";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import { hexWidth, hexHeight } from "../World/World";

export const MiniMap: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [mouseDownCoords, setMouseDownCoords] = useState({ x: 0, y: 0 });

  const nodeRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (isMapOpen && containerRef.current && imageRef.current) {
      const container = containerRef.current.getBoundingClientRect();
      const image = imageRef.current.getBoundingClientRect();

      const newBounds = {
        left: container.width - image.width - 50,
        top: container.height - image.height - 120,
        right: 0,
        bottom: 0,
      };

      setBounds(newBounds);

      const centerX = (container.width - image.width) / 2 - 150;
      const centerY = (container.height - image.height) / 2;
      setPosition({ x: centerX, y: centerY });
    }
  }, [isMapOpen]);

  const clickOnMapBg = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setIsMapOpen(false);
    }
  };

  const handleDragStop = (e: any, data: DraggableData) => {
    const x = Math.round(data.x);
    const y = Math.round(data.y);

    let coordinates: { x: number; y: number } = { x: 0, y: 0 };

    if ("clientX" in e) {
      coordinates = { x: e.clientX, y: e.clientY };
    } else if (e.type === "touchend" && e.changedTouches) {
      coordinates = {
        x: Math.floor(e.changedTouches[0].clientX),
        y: Math.floor(e.changedTouches[0].clientY),
      };
    }

    const isClick =
      Math.abs(coordinates.x - mouseDownCoords.x) < 2 &&
      Math.abs(coordinates.y - mouseDownCoords.y) < 2;

    setPosition({ x, y });

    if (isClick) {
      const minX = -210;
      const maxX = 125;
      const minY = -90;
      const maxY = 100;

      const canvasWidth = 3350;
      const canvasHeight = 1450;

      const cellsNumberX = maxX - minX;
      const cellsNumberY = maxY - minY;

      let clickX: number, clickY: number;

      if ("offsetX" in e) {
        clickX = (e as MouseEvent).offsetX;
        clickY = (e as MouseEvent).offsetY;
      } else if (e.type === "touchend" && e.changedTouches) {
        const rect = (e.target as HTMLElement).getBoundingClientRect();
        clickX = e.changedTouches[0].clientX - rect.left;
        clickY = e.changedTouches[0].clientY - rect.top;
      } else {
        return;
      }

      const percentX = clickX / canvasWidth;
      const percentY = clickY / canvasHeight;

      const xCellCoord = Math.floor(percentX * cellsNumberX) + minX;
      const yCellCoord = Math.floor(percentY * cellsNumberY) + minY;

      dispatch({
        type: ActionType.CHANGE_WORLD_COORDINATES,
        x: -(xCellCoord - 2) * hexWidth * state.worldScale,
        y: -(yCellCoord - 2) * hexHeight * state.worldScale,
      });

      setIsMapOpen(false);
    }
  };

  return (
    <div className="fixed top-16 left-4 text-3xl cursor-pointer">
      <FaMapMarkedAlt
        className="antique-font-hover antique p-1 w-8 h-8"
        onClick={() => setIsMapOpen(true)}
      />
      {isMapOpen && (
        <div>
          <div
            ref={containerRef}
            className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 overflow-hidden"
            onClick={clickOnMapBg}
          ></div>
          <div className="fixed top-16 left-6 bottom-20 right-6 overflow-hidden shadow-lg">
            <Draggable
              nodeRef={nodeRef}
              bounds={bounds}
              position={position}
              onStop={handleDragStop}
              onStart={(e: DraggableEvent) => {
                let coordinates: { x: number; y: number } = { x: 0, y: 0 };

                if ("clientX" in e) {
                  coordinates = { x: e.clientX, y: e.clientY };
                } else if (e.type === "touchstart" && e.touches) {
                  coordinates = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY,
                  };
                }
                setMouseDownCoords(coordinates);
              }}
            >
              <div ref={nodeRef} className="cursor-move_disabled">
                <img
                  ref={imageRef}
                  src="/map.jpg"
                  alt="map"
                  className="w-[3350px] h-[1450px] max-w-none"
                  draggable="false"
                />
              </div>
            </Draggable>
          </div>
        </div>
      )}
    </div>
  );
};
