import { useContext } from "react";
import { AppContext } from "../State/Context";
import { MenuBtn } from "./MenuBtn";
import { ActionType } from "../State/State";
import { IoMdChatboxes } from "react-icons/io";

export const MenuTop = () => {
  const { dispatch } = useContext(AppContext);
  const onMostViewedClick = () => {
    dispatch({ type: ActionType.TOGGLE_MOST_VIEWED_POPUP });
  };

  return (
    <div className="fixed top-0 left-0 right-0 p-3 z-50">
      <div className="select-none absolute top-0 left-0 right-0 p-2 bg-[antiquewhite]  flex justify-between items-center">
        <div className="text-lg pl-2">hexagen⬢world</div>
        <div className="flex">
          <div
            onClick={() =>
              dispatch({ type: ActionType.TOGGLE_GLOBAL_CHAT_POPUP })
            }
            className="text-[20px] mr-4 cursor-pointer"
          >
            <IoMdChatboxes />
          </div>
          <div
            onClick={onMostViewedClick}
            className="text-center pr-4 cursor-pointer hover:underline"
          >
            Ratings
          </div>
          <MenuBtn />
        </div>
      </div>
    </div>
  );
};
