interface BasicPopupProps {
  children?: React.ReactNode;
  onClose?: () => void;
}

export const BasicPopup: React.FC<BasicPopupProps> = ({
  children,
  onClose,
}) => {
  const bgClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose?.();
    }
  };
  return (
    <div
      onClick={bgClickHandler}
      className="fixed bg-black bg-opacity-40 shadow inset-0 flex justify-center items-start p-4 sm:p-0"
    >
      <div className="z-[1000] antique w-full sm:w-[580px] sm:ml-8 sm:mt-14 mt-12 p-6 relative max-h-[calc(100vh-114px)] overflow-auto">
        <div
          onClick={onClose}
          className="absolute right-[17px] top-[6px] select-none cursor-pointer text-[25px]"
        >
          ✕
        </div>
        <div className="overflow-auto">{children}</div>
        <div className="text-xl w-full text-center select-none">⬢</div>
      </div>
    </div>
  );
};
