import { useContext } from "react";
import useAsyncEffect from "use-async-effect";
import "./App.scss";
import { MenuBottom } from "./Menu/MenuBottom";
import { MenuTop } from "./Menu/MenuTop";
import { HexGeneratePopup } from "./Popups/HexGeneratePopup";
import { HexInfoPopup } from "./Popups/HexInfoPopup";
import { LoginPopup } from "./Popups/LoginPopup";
import { PlayerProfilePopup } from "./Popups/PlayerProfilePopup";
import { ActionType } from "./State/State";
import { SystemMessage } from "./SystemMessage/SystemMessage";
import { World } from "./World/World";
import { RequestMethod, askAPI } from "./utils/api";
import { Player } from "./types";
import { PlayerInfoPopup } from "./Popups/PlayerInfoPopup";
import { MenuPopup } from "./Menu/MenuPopup";
import { AppContext } from "./State/Context";
import { AboutGamePopup } from "./Popups/AboutGamePopup";
import { ZoomController } from "./Zoom/Zoom";
import { GeneratedPopupAward } from "./Popups/GeneratedAwardPopup";
import { RatingsPopup } from "./Popups/RatingsPopup";
import { MiniMap } from "./Map/Map";
import { GlobalChatPopup } from "./Popups/GlobalChatPopup";

function App() {
  const { state, dispatch } = useContext(AppContext);

  useAsyncEffect(() => {
    setInterval(async () => {
      const player = await askAPI<Player>("/api/player", RequestMethod.GET);
      dispatch({ type: ActionType.UPDATE_PLAYER, player });
    }, 10000);
  }, []);

  return (
    <div className="App p-2">
      <MenuTop />
      <World />
      <ZoomController />
      <MiniMap />
      <MenuBottom />
      {state.isMostViewedPopupOpen && <RatingsPopup />}
      {state.isHexInfoPopupOpen && <HexInfoPopup />}
      <PlayerProfilePopup />
      <LoginPopup />
      <HexGeneratePopup />
      {state.isPlayerInfoPopupOpen && <PlayerInfoPopup />}
      {state.isAboutGamePopupOpen && <AboutGamePopup />}
      <GeneratedPopupAward />
      <SystemMessage />
      {state.isMenuPopupOpen && <MenuPopup />}
      {state.isGlobalChatPopupOpen && <GlobalChatPopup />}
    </div>
  );
}

export default App;
